import { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout";
import Link from "next/link";
import useSWR from "swr";
import Head from "next/head";
import algoliasearch from "algoliasearch/lite";
import aa from "search-insights";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
  connectStateResults
} from "react-instantsearch-dom";
import { useDebouncedCallback } from "use-debounce";

import eventiveConfig from "../eventive.config.json";
import api from "../services/api";
import { useAuth } from "../hooks/useAuth";

import MediaSections from "../components/MediaSections";
import MediaGrid from "../components/MediaGrid";
import NoContentPlaceholder from "../components/NoContentPlaceholder";

import { useRouter } from "next/router";

const Results = connectStateResults(
  ({ searchState, searchResults, children }) => {
    return searchResults && searchResults.nbHits !== 0 ? (
      <div className="mt-10">
        <MediaGrid
          items={searchResults._rawResults[0].hits.reduce((arr, h) => {
            h.id = h.objectID;
            if (h.image) {
              h.image += "?w=640";
            }

            //SUNDANCE 2025 FILTER - ALLOW ONLY PUBLIC SCREENINGS
            let foundTag = false;
            if (h.tags && h.tags.length) {
              let hideTags = ["668424bdd8f5d4005ef296e8", "668422f05ad8a3003a0c6a46", "66842410cac7500074807ea4"];
              for (let t=0; t < hideTags.length; t++) {
                const thisTag = hideTags[t];
                if (h.tags.includes(thisTag)) {
                  foundTag = true;
                }
              }
            }
            if (!foundTag) {
              arr.push(h);
            }
            return arr;
          }, [])}
          isDiscoverySearch
          onItemClick={item => {
            aa("clickedObjectIDsAfterSearch", {
              eventName: "Click Search Result",
              index: searchResults.index,
              queryID: searchResults.queryID,
              objectIDs: [item.id],
              positions: [
                searchResults._rawResults[0].hits.findIndex(
                  h => h.objectID === item.id
                )
              ]
            });
          }}
        />
      </div>
    ) : searchResults?.nbHits === 0 ? (
      <NoContentPlaceholder
        text="No results found – try another search!"
        emoji="🔍"
      />
    ) : (
      <div />
    );
  }
);

export default function Index({ initialData }) {
  const router = useRouter();
  const { apiLoaded, user, singleOrganization } = useAuth();
  const { data, mutate, error } = useSWR(
    `watch/discovery`,
    k => api.get(k).then(r => r.data),
    { initialData }
  );
  useEffect(() => {
    if (apiLoaded) mutate(initialData, false);
  }, [apiLoaded]);

  // In single-organization mode, always show the legacy-style home screen instead of
  // this new discovery portal.
  useEffect(() => {
    if (singleOrganization) {
      router.replace("/home");
    }
  }, [typeof window, singleOrganization]);

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsMobile(!!window.matchMedia?.("(max-width: 600px)")?.matches);
    }
  }, [typeof window]);

  const [searchClient, setSearchClient] = useState(null);

  useEffect(() => {
    if (data?.search && !searchClient) {
      setSearchClient(
        algoliasearch(data.search.applicationID, data.search.publicApiKey)
      );
      aa("init", {
        appId: data.search.applicationID,
        apiKey: data.search.publicApiKey
      });
      if (user) {
        aa("setUserToken", user.id);
      }
    }
  }, [data, searchClient]);

  const rawQuery = new URLSearchParams(router.asPath.split("?")[1]).get("q");
  const [searchState, setSearchState] = useState(
    rawQuery ? { query: rawQuery } : {}
  );
  const [query, setQuery] = useState(searchState.query || "");

  const [updateSearchState] = useDebouncedCallback(query => {
    if (query) {
      setSearchState({ query });
    }
    const method =
      router.asPath.indexOf("?") === -1 ? router.push : router.replace;
    method("/", `/${query ? `?q=${query}` : ""}`, {
      shallow: true
    });
  }, 250);
  const onSearchStateChange = updatedSearchState => {
    setQuery(updatedSearchState?.query || "");
    updateSearchState(updatedSearchState?.query);
  };

  useEffect(() => {
    if (!rawQuery) {
      onSearchStateChange({});
    }
  }, [rawQuery]);

  if (!data || singleOrganization) {
    return <Layout loader landing />;
  }

  return (
    <Layout landing={true}>
      <Head>
        <title>Eventive | Discover & watch independent film</title>
        <meta
          name="description"
          content="Stream independent film from film festivals, art house cinemas, virtual cinema screenings, classic repertory catalogs, and special events. Easily search the entire Eventive catalog for the freshest content anywhere."
        />
      </Head>
      <div className="mt-8 sm:mt-10 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            className="h-7 w-7 sm:h-10 sm:w-10 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
            />
          </svg>
        </div>
        <input
          id="search"
          className="form-input block w-full pl-12 sm:pl-15 text-xl sm:text-3xl bg-gray-700 border-gray-500 transition
           duration-150 ease-in-out focus:shadow-outline-gray focus:border-gray-400 text-white"
          placeholder={
            isMobile
              ? "Search Eventive..."
              : "Search Eventive to find channels, screenings, films, and more"
          }
          value={query}
          onChange={e => onSearchStateChange({ query: e.target.value })}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="none"
          spellCheck="false"
        />
        {query ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <svg
              className="h-7 w-7 sm:h-10 sm:w-10 text-gray-400 hover:text-gray-200 cursor-pointer"
              onClick={() => onSearchStateChange({})}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        ) : null}
      </div>
      {query && searchState?.query && data?.search && searchClient ? (
        <InstantSearch
          searchClient={searchClient}
          indexName={data.search.index}
        >
          <Configure query={searchState.query} clickAnalytics />
          <Results />
        </InstantSearch>
      ) : (
        <div className="mt-8 sm:mt-10">
          <MediaSections
            sections={data.sections}
            translateSectionTitles={true}
            isDiscoveryList
          />
        </div>
      )}
    </Layout>
  );
}

export async function getServerSideProps({ res, locale }) {
  if (res) {
    res.setHeader("Cache-Control", "s-maxage=1, stale-while-revalidate");
  }

  // Single org mode force user to go to the /home special landing page instead, rather than
  // to the discovery portal.
  if (eventiveConfig?.single_organization && res) {
    res.writeHead(307, {
      Location: "/home"
    });
    res.end();
    return { props: {} };
  }

  let props;
  try {
    const data = await api.get(`watch/discovery`).then(r => r.data);
    props = {
      initialData: data
    };
  } catch (e) {
    props = {};
  }

  return {
    props: {
      ...props
    }
  };
}
